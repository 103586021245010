import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import { PageContent } from '../components/PageContent'
import Container from '../components/Container'
import styled from 'styled-components'
import { breakpoint } from '../styles/variables'

const StyledContainer = styled(Container)`
    max-width: 700px;
    padding: 1.5rem 1rem 2rem;

    @media ${breakpoint.sm} {
        padding: 0 1rem 2rem;
    }

    @media ${breakpoint.md} {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        padding: 0;
    }

    @media ${breakpoint.lg} {
        margin-top: 4rem;
        margin-bottom: 5rem;
    }

    p,
    li,
    h3 {
        font-size: 0.875rem;
        line-height: 1.5;
    }

    a {
        color: #000000;
    }
`

const OrderedList = styled.ol`
    list-style-type: upper-roman;

    li {
        margin: 1.5rem 0;

        &::marker {
            font-size: 1rem;
        }
    }

    h2 {
        font-size: 1rem;
    }
`

const UnorderedList = styled.ul`
    list-style-type: disc;
    padding-left: 2rem;

    li {
        margin: 0.5em 0;

        ul {
            list-style-type: circle;
            padding-left: 2rem;
        }
    }
`

const StyledUl = styled(UnorderedList)`
    h3, p {
        display: inline;
    }
`

const NoBulletUl = styled(UnorderedList)`
    list-style-type: none;

    h3 {
        margin-bottom: 0.3em;
    }

    p {
        margin: 0;
    }
`


const Terms = () => {
    return (
        <>
            <SmallBanner title="Zásady zpracování osobních údajů" isLongTitle={true}/>
            <PageContent>
                <StyledContainer>
                    <p>
                        Účelem tohoto dokumentu je poskytnout Vám souhrnný a srozumitelný souhrn informací o zpracování těchto osobních údajů,
                        především jakým způsobem, v jakém rozsahu, za jakým účelem a po jak dlouhou dobu budeme osobní údaje zpracovávat
                        a dále Vás informovat i o všech Vašich individuálních právech, které můžete v souvislosti se zpracováním osobních údajů uplatnit.
                    </p>
                    <p>
                        Pokud byste něčemu z tohoto dokumentu nerozuměli, neváhejte se na nás obrátit prostřednictvím níže uvedených kontaktů a my Vám rádi vše podrobněji vysvětlíme.
                    </p>
                    <p>
                        Zároveň odkazujeme zejména na nařízení Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016,
                        o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů),
                        jakož i český adaptační zákon k tomuto nařízení č. 110/2019 Sb., o zpracování osobních údajů, dle kterých se náš vztah v souvislosti se zpracováním osobních údajů primárně řídí.
                    </p>
                    <OrderedList>
                        <li>
                            <h2>Kdo je správcem Vašich osobních údajů?</h2>
                            <p>Správcem osobních údajů je obecně osoba, která sama, popř. společně s jinými subjekty určuje účel a prostředky zpracování osobních údajů, za což nese i s tím spjatou odpovědnost.</p>
                            <p>Správcem osobních údajů pro účely těchto zásad zpracování osobních údajů je <strong>Julia Mandzyuk</strong>, {/* identifikační číslo [______],  */}se sídlem Březová 3473/19 466 02 Jablonec nad Nisou.</p>
                            <p>Kontaktovat jej můžete prostřednictvím e-mailu <a href="mailto:julia@cestu-j.cz">julia@cestu-j.cz</a>.</p>
                        </li>
                        <li>
                            <h2>Pověřenec pro ochranu osobních údajů</h2>
                            <p>Pověřenec pro ochranu osobních údajů nebyl jmenován.</p>
                        </li>
                        <li>
                            <h2>Naše hlavní zásady</h2>
                            <p>Při zpracování Vašich osobních údajů ctíme a respektujeme nejvyšší standardy ochrany osobních údajů a dodržujeme zejména následující zásady:</p>
                            <UnorderedList>
                                <li>Vaše osobní údaje zpracováváme pro stanovený účel, stanovenými prostředky a stanoveným způsobem, a pouze po dobu, která je nezbytně nutná vzhledem k účelům jejich zpracování;</li>
                                <li>Vaše osobní údaje chráníme a jejich zpracování zajišťujeme s nejvyšší bezpečností tak, abychom zabránili jakémukoliv neoprávněnému nebo nahodilému přístupu k Vašim osobním údajům, jejich změně, zničení či ztrátě, neoprávněným přenosům, jejich jinému neoprávněnému zpracování;</li>
                                <li>dodržujeme odpovídající technická a organizační opatření, aby byla zajištěna úroveň zabezpečení odpovídající všem možným rizikům; veškeré osoby, které přicházejí do styku s osobními údaji, mají povinnost dodržovat mlčenlivost o informacích získaných v souvislosti se zpracováváním těchto údajů.</li>
                            </UnorderedList>
                        </li>
                        <li>
                            <h2>Jaké osobní údaje zpracováváme?</h2>
                            <p>Charakter osobních údajů, které od Vás získáváme a následně zpracováváme, závisí především na tom, v jakém jste s námi vztahu (smluvní partner, zákazník, návštěvník webu atp.) a pro jaký účel jsou osobní údaje získávány.</p>
                            <p>Typově od Vás budeme získávat především následující informace:</p>
                            <NoBulletUl>
                                <li>
                                    <h3>Identifikační údaje</h3>
                                    <p>Vaše jméno, příjmení, titul, pohlaví, datum narození, rodné číslo, adresa bydliště, státní příslušnost, podpis.</p>
                                </li>
                                <li>
                                    <h3>Kontaktní údaje</h3>
                                    <p>Adresa bydliště, korespondenční adresa, e-mailová adresa, telefonní číslo, popř. jiné údaje, které nám poskytnete za účelem kontaktu.</p>
                                </li>
                                <li>
                                    <h3>Záznamy z naší vzájemné komunikace</h3>
                                    <p>Především záznamy z naší e-mailové či písemné komunikace mezi námi. Nevyužíváme systém nahrávání záznamu telefonního hovoru.</p>
                                </li>
                                <li>
                                    <h3>Údaje o Vaší plánované cestě</h3>
                                    <p>Lokalita, Vaše ubytovací zařízení a další podrobnosti k ubytování, číslo a další údaje o letence či jiné jízdence, popřípadě jakékoli jiné informace, které nám v této souvislosti poskytnete.</p>
                                </li>
                                <li>
                                    <h3>Údaje z Vámi vyplněného formuláře</h3>
                                    <p>Informace o Vašich zálibách, cestování, zážitcích apod.</p>
                                </li>
                                <li>
                                    <h3>Fakturační údaje</h3>
                                    <p>Údaje z vystavených faktur.</p>
                                </li>
                                <li>
                                    <h3>Ostatní údaje</h3>
                                    <p>Například údaje, které získáváme z Vašeho internetového prohlížeče nebo na základě ukládání cookies.</p>
                                </li>
                            </NoBulletUl>
                        </li>
                        <li>
                            <h2>Jak osobní údaje získáváme?</h2>
                            <p>Osobní údaje nám poskytujete především Vy dobrovolnou formou provedením objednávky, vyplňováním formulářů či v rámci informací a údajů poskytovaných za účelem uzavření smlouvy o spolupráci či jiné obdobné smlouvy. Osobní údaje rovněž získáváme z vlastní činnosti. Osobní údaje dále můžeme získat od třetích stran, se kterými spolupracujeme či nás s nimi pojí nějaký jiný vztah, a které mají oprávnění k zpracování a sdílení Vašich osobních údajů.</p>
                        </li>
                        <li>
                            <h2>K jakým účelům osobní údaje získáváme?</h2>
                            <p>Vaše osobní údaje používáme k účelům vyplývajícím z naší činnosti, přičemž platí, že pro většinu takových zpracování nepotřebujeme získat Váš souhlas, neboť je nám zpracování umožněno přímo na základě právních předpisů. Jsme zároveň oprávněni k tomu, abychom (tam kde to připadá v úvahu) zpracovávali osobní údaje či jejich kategorii i pro různé účely.</p>
                            <p>V případě, že je pro některý z účelů nutné získání Vašeho souhlasu, můžete tento souhlas odvolat, a to kdykoli v průběhu doby, na kterou bude udělen. Upozorňujeme pouze, že odvolání souhlasu má účinky do budoucna, není jím tedy dotčena zákonnost (a oprávněnost) zpracování osobních údajů do okamžiku jeho odvolání.</p>
                            <p>Konkrétní hlavní účely zpracování osobních údajů jsou poté následující:</p>
                            <StyledUl>
                                <li>
                                    <h3>provozování obchodu (vyřizování a správa objednávek, evidence zákazníků, atp.)</h3>
                                    <p> – právním základem pro toto zpracování je uzavření a plnění smlouvy a ochrana našich oprávněných zájmů;</p>
                                </li>
                                <li>
                                    <h3>uzavírání a plnění smluv o spolupráci či jiných smluv uzavřených s našimi partnery</h3>
                                    <p> – právním základem pro toto zpracování je uzavření a plnění smlouvy;</p>
                                </li>
                                <li>
                                    <h3>komunikace s Vámi a jinými osobami v rámci naší činnosti a za účelem zlepšování našich služeb</h3>
                                    <p> – právním základem pro toto zpracování je uzavření a plnění smlouvy a ochrana našich oprávněných zájmů;</p>
                                </li>
                                <li>
                                    <h3>přímý marketing (zasílání newsletterů či jiných marketingových e-mailů, sms či jiných obdobných činností)</h3>
                                    <p> – právním základem pro toto zpracování je ochrana našich oprávněných zájmů;</p>
                                </li>
                                <li>
                                    <h3>umožnění účasti v našich soutěžích, věrnostních programech a dalších obdobných akcích a jejich správa</h3>
                                    <p> – základním právním základem pro toto zpracování je uzavření a plnění smlouvy, nicméně je možné, že v konkrétním případě budeme vyžadovat Váš souhlas;</p>
                                </li>
                                <li>
                                    <h3>poskytnutí součinnosti orgánům veřejné moci</h3>
                                    <p> – právním základem pro toto zpracování je splnění našich právních povinností;</p></li>
                                <li>
                                    <h3>zřízení a ochrana zákonných práv, ochrana našeho soukromí, naší bezpečnosti či našeho majetku a/nebo práv, Vás nebo jiných osob a snaha o využití dostupných nápravných opatření či omezení naší škody</h3>
                                    <p> – právním základem pro toto zpracování je ochrana našich oprávněných zájmů a splnění právních povinností, které se na nás vztahují.</p>
                                </li>
                            </StyledUl>
                        </li>
                        <li>
                            <h2>Jak dlouho Vaše osobní údaje budeme uchovávat?</h2>
                            <p>Činíme veškeré kroky, abychom zajistili, že osobní údaje, které budeme shromažďovat a zpracovávat, bezpečně odpovídaly a sloužily zamýšlenému účelu. Osobní údaje tedy budeme uchovávat pouze po nezbytně nutnou dobu v souladu se zachováním zásady minimalizace osobních údajů. Průběžně posuzujeme, jestli nadále trvá potřeba zpracovávat určité osobní údaje potřebné pro daný účel. Pokud zjistíme, že již nejsou potřebné pro žádný z účelů, pro které byly zpracovávány, údaje zlikvidujeme.</p>
                            <p>Níže uvádíme příklad některých z dob, které v této souvislosti dodržujeme:</p>
                            <UnorderedList>
                                <li>osobní údaje v souvislosti s plněním naší smlouvy uchováváme po dobu korespondující příslušným promlčecím dobám;</li>
                                <li>pokud od Vás některé osobní údaje získáme ještě před uzavřením smlouvy a k tomuto uzavření nakonec nedojde, budeme uchovávat osobní údaje maximálně 1 rok od jejich získání;</li>
                                <li>pro účely tzv. přímého marketingu osobní údaje uchováváme po dobu trvání našeho smluvního vztahu a maximálně 1 rok po jeho skončení;</li>
                                <li>pokud nám udělíte souhlas se zpracováním osobních údajů za účelem jiného než přímého marketingu, budeme tyto osobní údaje uchovávat po dobu uvedenou v takovém souhlasu, případně po dobu, než takový souhlas odvoláte;</li>
                                <li>účetní a daňové záznamy, kterými dokládáme naše účetnictví a plníme daňové povinnosti (a které mohou obsahovat zejména fakturační osobní údaje), uchováváme po dobu stanovenou zvláštními právními předpisy počínaje koncem příslušného účetního či zdaňovacího období.</li>
                            </UnorderedList>
                        </li>
                        <li>
                            <h2>S kým Vaše osobní údaje sdílíme?</h2>
                            <p>Vaše osobní údaje zásadně zpracováváme interně. Platí však, že pokud je to nezbytné pro dosažení některého z účelu výše, můžeme Vaše osobní údaje sdílet i se třetími osobami, a to jak v pozici zpracovatelů, tak i samostatných, popř. společných správců. V takovém případě se však zavazujeme osobní údaje předat pouze takovým subjektům, u nichž je garantována dostatečná míra ochrany osobních údajů v souladu s předpisy na ochranu osobních údajů. Zároveň jsme povinni Vaše osobní údaje v některých případech také předávat orgánům veřejné moci, pokud tak stanoví právní předpisy. Konečně některé osobní údaje sdílíme s třetími osobami na základě Vašeho předchozího souhlasu.</p>
                            <p>Konkrétně Vaše osobní údaje můžeme za splnění podmínek zpřístupnit zejména následujícím subjektům:</p>
                            <UnorderedList>
                                <li>naši smluvní partneři – osobní údaje sdílíme za účelem poskytování našich služeb i s jinými subjekty jako jsou naše spolupracující osoby a poddodavatelé, jakož i poskytovatelé IT služeb, subjekty vymáhající pohledávky, advokátní kanceláře, účetní a daňoví poradci a poskytovatelé tiskových, reklamních a marketingových služeb;</li>
                                <li>orgány veřejné moci a třetí osoby účastnící se soudního nebo obdobného řízení – v souladu s dodržením našich dalších zákonných povinností jsme povinni předávat Vaše osobní údaje taktéž příslušným orgánům veřejné moci jako například orgány činné v trestním řízení. V rámci případného sporného řízení budou Vaše osobní údaje sdíleny i se třetími osobami jakožto účastníky takovýchto řízení;</li>
                                <li>ostatní třetí osoby – osobní údaje jsme dále oprávněni sdílet například s příjemci plateb, poskytovateli služeb v případě mimořádných událostí (hasičské, policejní a lékařské pohotovostní služby), atp.</li>
                            </UnorderedList>
                        </li>
                        <li>
                            <h2>Jaká máte v souvislosti se zpracováním osobních údajů práva?</h2>
                            <p>V souvislosti se zpracováním osobních údajů máte celou řadu práv, která můžete vůči nám uplatnit prostřednictvím našich kontaktů uvedených výše.</p>
                            <p>Vaše žádost o uplatnění z některých práv níže bude vyřízena nejpozději do jednoho měsíce (popř. do třech měsíců v odůvodněných případech, přičemž o prodloužení této lhůty Vás budeme dopředu včas informovat) s tím, že po Vás nebudeme požadovat ani uhrazení žádného poplatku. Platí však zároveň, že v případě, že obdržíme zjevně nedůvodnou či nepřiměřenou žádost (např. pokud dojde k opakované žádosti v krátkém období po sobě), jsme v takovém případě oprávněni požadovat přiměřený administrativní poplatek na pokrytí našich nákladů na vyřízení této žádosti.</p>
                            <StyledUl>
                                <li>
                                    <h3>Právo na přístup k Vašim osobním údajům</h3>
                                    <p> – máte právo žádat informace o tom, zda Vaše osobní údaje zpracováváme a pokud ano, tak rovněž i o poskytnutí výpisu těchto údajů, jakož i informace o tom, za jakými účely je zpracováváme a po jak dlouhou dobu plánujeme uchovávat. První kopii výpisu zpracovávaných údajů Vám poskytneme zdarma, za každou další si můžeme účtovat administrativní poplatek na pokrytí našich nákladů.</p>
                                </li>
                                <li>
                                    <h3>Právo na opravu a doplnění Vašich osobních údajů</h3>
                                    <p>– pokud zjistíte, že osobní údaje, které o Vás zpracováváme, jsou nepřesné, neaktuální nebo neúplné, můžete nás požádat o opravu či doplnění.</p>
                                </li>
                                <li>
                                    <h3>Právo na výmaz</h3>
                                    <p> – dále nás můžete požádat o to, abychom Vaše osobní údaje, které o Vás zpracováváme, bez zbytečného odkladu vymazali. Tomuto požadavku jsme však povinni vyhovět pouze za předpokladu, že:</p>
                                    <ul>
                                        <li>zpracování osobních údajů již není potřebné pro účely, pro které byly shromážděny nebo jinak zpracovány; nebo</li>
                                        <li>jste odvolali svůj souhlas, na základě kterého jsme osobní údaje zpracovávali (a současně neexistuje žádný jiný právní důvod pro zpracování); nebo</li>
                                        <li>jste vznesli úspěšně námitky proti zpracování a neexistují převažující oprávněné důvody pro zpracování; nebo</li>
                                        <li>osobní údaje byly zpracovány protiprávně; nebo</li>
                                        <li>osobní údaje musí být vymazány z důvodu splnění naší právní povinnosti.</li>
                                    </ul>
                                    <p>Vašemu požadavku na vymazání osobních údajů poté nevyhovíme zejména, pokud je jejich zpracování i nadále nezbytné pro splnění naší zákonné povinnosti nebo pro určení, výkon nebo obhajobu našich právních nároků.</p>
                                </li>
                                <li>
                                    <h3>Právo na omezení zpracování</h3>
                                    <p> – můžete požádat, abychom omezili zpracování Vašich osobních údajů (tzn., abychom je nevyužívali, avšak zároveň aby nedošlo k jejich úplné likvidaci), avšak pouze v následujících případech:</p>
                                    <ul>
                                        <li>zpochybnili jste přesnost osobních údajů (zpracování bude poté omezeno na dobu potřebnou pro naše ověření přesnosti); nebo</li>
                                        <li>zpracování osobních údajů je protiprávní a nemáte zájem o výmaz; nebo</li>
                                        <li>již nepotřebujeme osobní údaje pro účely zpracování, ke kterým byly získány, avšak Vy je požadujete pro určení, výkon nebo obhajobu Vašich právních nároků; nebo</li>
                                        <li>vznesli jste námitku proti zpracování a probíhá ověření, zda naše oprávněné důvody pro zpracování převažují či nepřevažují proti Vaší námitce.</li>
                                    </ul>
                                    <p>Prosíme, abyste vzali na vědomí, že i v případě, kdy dojde k omezení zpracování, i nadále budeme moci osobní údaje zpracovávat pokud:</p>
                                    <ul>
                                        <li>k tomu budeme mít Váš souhlas; nebo</li>
                                        <li>to bude potřeba pro určení, výkon nebo obhajobu našich právních nároků; nebo</li>
                                        <li>to bude potřeba pro ochranu práv jiných fyzických nebo právnických osob.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Právo na přenositelnost osobních údajů</h3>
                                    <p> – pokud zpracování probíhá na základě Vašeho souhlasu nebo za účelem uzavření či plnění smlouvy, máte právo, abychom Vám na Vaši žádost poskytli osobní údaje, které se Vás týkají, ve strukturovaném, běžně používaném a strojově čitelném formátu, popřípadě na to, abychom tyto údaje předali jinému správci.</p>
                                </li>
                                <li>
                                    <h3>Právo vznést námitku</h3>
                                    <p> – Máte právo vznést námitku proti zpracovávání osobních údajů, které je prováděno pro účely našich oprávněných zájmů. Pokud Vám následně nedokážeme prokázat, že máme závažné důvody pro takovéto zpracování, které převažují nad Vašimi zájmy nebo právy a svobodami, nebo které je nutné pro určení, výkon nebo obhajobu právních nároků, bude zpracování Vašich osobních údajů zastaveno.</p>
                                    Máte dále právo kdykoliv vznést námitku proti zpracování Vašich osobních údajů pro účely přímého marketingu. V případě vznesení takovéto námitky nebudou tyto osobní údaje pro tyto účely již nadále zpracovávány.
                                </li>
                                <li>
                                    <h3>Právo odvolat svůj souhlas se zpracováním osobních údajů</h3>
                                    <p> – pokud ke zpracování Vašich údajů využíváme Váš souhlas, jste oprávněni tento souhlas kdykoliv odvolat. Odvolání souhlasu má posléze účinky pouze do budoucna, tudíž nebude jakkoliv narušena zákonnost předcházejícího zpracování. Odvolání souhlasu musí obsahovat informaci o tom, kdo odvolání podává (připojte tedy jméno, příjmení, adresu bydliště, datum narození, popř. další Vaše identifikační údaje) a jaký konkrétní souhlas odvoláváte a v jakém rozsahu.</p>
                                </li>
                                <li>
                                    <h3>Máte právo podat stížnost u Úřadu pro ochranu osobních údajů</h3>
                                    <p> – pokud se z jakýchkoliv důvodů, že zpracování Vašich údajů neprobíhá v pořádku, můžete se obrátit na Úřad pro ochranu osobních údajů se sídlem Pplk. Sochora 27, 170 00 Praha 7, email:&nbsp;<a href="mailto:posta@uoou.cz">posta@uoou.cz</a>, telefon:&nbsp;<a href="tel:+420234665111">+420&nbsp;234&nbsp;665&nbsp;111</a>.</p>
                                </li>
                            </StyledUl>
                        </li>
                        <li>
                            <h2>Používáme automatizované individuální rozhodnutí?</h2>
                            <p>V rámci naší činnosti žádné osobní údaje automaticky nezpracováváme, ani neužíváme automatizované rozhodování.</p>
                        </li>
                        <li>
                            <h2>Osobní údaje jiných osob</h2>
                            <p>Pokud nám poskytnete osobní údaje jiných osob, zavazujete se: (i) že budete dané osoby informovat o obsahu tohoto dokumentu a (ii) že si zajistíte veškeré zákonem požadované souhlasy ke shromažďování, používání, zveřejňování a předávání (včetně mezinárodního předávání) osobních údajů daných osob v souladu s tímto dokumentem.</p>
                        </li>
                        <li>
                            <h2>Předáváme osobní údaje do třetí země nebo mezinárodní organizace?</h2>
                            <p>Osobní údaje nebudeme předávat do zemí mimo Evropskou unii nebo Evropský hospodářský prostor, ani do žádné mezinárodní organizace, ledaže k tomu budeme povinni z důvodu splnění našich právních povinností.</p>
                        </li>
                        <li>
                            <h2>Využíváme některé služby analýzy dat?</h2>
                            <p>Za účelem možné individualizace obsahu našich webových stránek služby umožňující analýzu dat využíváme, zejména Google Analytics a soubory cookies.</p>
                            <p>K tomu, abychom mohli využívat, resp. ukládat Vaše soubory cookies si však vždy vyžadujeme Váš předchozí souhlas při každé Vaší návštěvě našich webových stránek.</p>
                        </li>
                        <li>
                            <h2>Bezpečnost</h2>
                            <p>V rámci snahy o co největší zabezpečení Vašich osobních údajů činíme odpovídající technická, fyzická, právní a organizační opatření v souladu s platnými zákony o soukromí a bezpečnosti dat. Máte-li důvod se domnívat, že Vaše komunikace s námi již není bezpečná (např. pokud nabudete dojmu, že bezpečnost jakýchkoli osobních údajů, které jste nám svěřili, byla ohrožena), neprodleně nás, prosím, na tuto skutečnost upozorněte prostřednictvím kontaktních údajů uvedených výše.</p>
                        </li>
                    </OrderedList>

                    Tyto zásady jsou platné a účinné od 08/2022.


                </StyledContainer>
            </PageContent>

        </>
    )
}

export default Terms

export const Head = () => (
    <>
      <title>Zásady zpracování osobních údajů | Cestu-j</title>
      <meta name="description" content="Cestovní itineráře na míru" />
    </>
  )